<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-inline-flex tw-items-center">
          {{ $t('_gainLoss') }}
          <tooltip-select tip="_gainLoss" class="tw-ml-2"></tooltip-select>
        </h3>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <v-layout row wrap>
        <v-flex xs12 v-if="isLoading">
          <v-progress-linear indeterminate color="green" :active="isLoading > 0"></v-progress-linear>
        </v-flex>
        <v-flex xs12 v-if="!isLoading && org">
          <taxes-quick-start
            v-if="org && !org.taxStrategyType"
            v-on:saved="refresh"
            :taxStrategy="org.taxStrategyType"
          />
          <tax-transactions v-else></tax-transactions>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import taxesQuickStart from '../../components/taxes/quickstart/TaxesQuickStart';
import taxTransactions from '../../components/taxes/TaxTransactions';

export default {
  props: [],
  data() {
    return {
      org: undefined,
      isLoading: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$apollo.queries.org.refetch();
    },
  },
  apollo: {
    org: {
      query: gql`
        query org($orgId: ID!) {
          org(id: $orgId) {
            id
            taxStrategyType
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    taxesQuickStart,
    taxTransactions,
  },
};
</script>
