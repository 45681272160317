<template>
  <div>
    <v-form ref="costAverageStep">
      <v-layout row wrap v-for="(line, line_index) in lines" :key="line_index">
        <v-flex xs3>
          <v-text-field
            v-model="line.ticker"
            autocapitalize="characters"
            label="Ticker"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>
        <v-flex xs1 class="pa-4">
          <v-icon @click="deleteLine(line, line_index)">delete</v-icon>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs2><a @click="addLine()">Add Excluded Coin+</a></v-flex>
      </v-layout>
      <v-layout row wrap class="my-3">
        <v-flex xs12>
          <v-btn color="primary" @click="goNext">Next</v-btn>
          <v-btn flat @click="back">Back</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
// import moment from "moment";
// import gql from "graphql-tag";

export default {
  props: ['next', 'back', 'context', 'save'],
  data() {
    return {
      isLoading: 0,
      lines: [
        {
          ticker: '',
        },
      ],
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
        required: (value) => !!value || 'Required.',
      },
    };
  },
  mounted() {
    if (this.context.excludedCoins && this.context.excludedCoins.length > 0) {
      this.lines = this.context.excludedCoins.map((m) => {
        return {
          ticker: m,
        };
      });
    }
  },
  methods: {
    addLine() {
      this.lines.push({
        ticker: '',
      });
    },
    deleteLine(line, index) {
      this.lines.splice(index, 1);
    },
    goNext() {
      const list = [];
      for (const l of this.lines) {
        // validate tickers
        if (l.ticker !== '') {
          list.push(l.ticker);
        }
      }
      this.context.excludedCoins = list;
      this.next();
    },
  },
};
</script>
