<template>
  <div>
    <div class="headline">Tax starting date?</div>
    <p>
      All transactions before this data will be marked as ignored (which will ignore them from tax matching, balance
      reports, and more), and we'll create starting transactions for any starting balances.
    </p>

    <v-alert :value="saveErrors.length > 0">
      <ul>
        <li v-for="(error, ei) in saveErrors" v-bind:key="`e-${index}-${ei}`">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <v-form ref="dateForm" v-model="valid">
      <v-checkbox label="Set start date" v-model="context.useStartDate"></v-checkbox>
      <v-date-picker
        v-model="context.startDate"
        :landscape="true"
        :max="dateMax"
        v-if="context.useStartDate"
      ></v-date-picker>
    </v-form>

    <v-btn color="primary" @click="save" :loading="saving">Save and Finish</v-btn>
    <v-btn flat @click="back" :disabled="saving">Back</v-btn>
  </div>
</template>
<script>
import moment from 'moment-timezone';
export default {
  props: ['next', 'back', 'context', 'save', 'saveErrors', 'saving'],
  data() {
    return {
      categorized: 5,
      total: 100,
      valid: true,
      setStartDate: false,
      // startDate: ""
    };
  },
  methods: {},
  computed: {
    dateMax() {
      const date = moment().format('L');
      const ret = moment.utc(date).hours(23).minutes(59).second(59);
      return ret.toISOString();
    },
  },
};
</script>
