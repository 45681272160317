<template>
  <v-layout row wrap>
    <div>
      <p :style="{ color: 'red', fontWeight: 'bold' }">
        ALERT: Its officially 12+ months since we launched our new gain loss capability!
        <br />
        <br />
        The current gain loss scenario runner will be retired and unavailable Effective April 30th 2024 in favor of the
        new enhanced "inventory view" gain loss capability.
        <br />
        Please reach out to our support team via In App Chat for assistance setting up your gain loss information using
        the inventory view feature.
        <br />
        <br />
      </p>
    </div>
    <v-flex xs12 class="mb-3">
      <v-card>
        <v-layout row wrap class="pa-3">
          <v-flex xs3>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              min-width="290px"
              v-model="startDateShow"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field
                slot="activator"
                label="Gain Loss Start Date"
                v-model="startDate"
                readonly
                data-testId="tax-scenarios-gain-loss-start-date-input"
              ></v-text-field>
              <v-date-picker
                v-model="startDate"
                :max="endDate"
                no-title
                scrollable
                actions
                @input="startDateShow = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs3 offset-xs1>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              min-width="290px"
              v-model="endDateShow"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field slot="activator" label="Gain Loss End Date" v-model="endDate" readonly></v-text-field>
              <v-date-picker
                v-model="endDate"
                :min="startDate"
                no-title
                scrollable
                actions
                @input="endDateShow = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2 offset-xs3>
            <v-btn @click="runReport" data-testId="tax-scenarios-run-report-btn">Run Scenario</v-btn>
          </v-flex>
          <v-flex xs12 data-testId="tax-scenarios-tax-method-select">
            <v-select
              :items="taxMethods"
              :label="$t('_taxStrategy')"
              v-model="taxStrategy"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-checkbox
              :label="$tc('_taxesCapitalizeFees')"
              v-model="capitalizeFees"
              data-testId="tax-scenarios-capitalize-fees-checkbox"
            ></v-checkbox>
          </v-flex>
          <v-flex xs3 v-if="allowSpecIdNetZero">
            <v-checkbox
              label="Spec Id Net Zero"
              v-model="specIdNetZero"
              data-testId="tax-scenarios-net-zero-checkbox"
            ></v-checkbox>
          </v-flex>
          <v-flex xs3>
            <v-checkbox
              label="Ignore NFTs"
              v-model="ignoreNFTs"
              data-testId="tax-scenarios-ignore-nfts-checkbox"
            ></v-checkbox>
          </v-flex>
          <v-flex xs3>
            <v-checkbox
              label="Calculate Impairment"
              v-model="impair"
              v-if="allowImpairment"
              data-testId="tax-scenarios-calculate-impairment-checkbox"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
          </v-flex>
          <v-flex xs12>
            <v-alert type="warning" :value="hasError">
              <div class="div-grid" data-testId="tax-scenarios-problem-report-div">
                <div>Problem Generating Report</div>
                <div v-for="(err, index) in error" v-bind:key="`error-${index}`">
                  {{ err }}
                </div>
              </div>
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-footer style="padding: 10px 12px">
              <fa class="tw-text-inherit" icon="fa-regular fa-clock" />
              <!-- <fa icon="fa-solid fa-clock" class="tw-text-success-300 tw-transform tw--rotate-45" /> -->
              {{ $tc('_timezone') + ': ' + currentTimezone }}
            </v-footer>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs12 v-if="reportData">
      <v-layout row wrap>
        <v-flex xs2 offset-xs10>
          <v-btn
            v-if="reportData.actionsDownloadUrl"
            :href="reportData.actionsDownloadUrl"
            data-testId="tax-scenarios-download-actions-csv-btn"
          >
            Download Actions CSV
          </v-btn>
          <v-btn
            v-if="reportData.largeFileDownloadUrl"
            :href="reportData.largeFileDownloadUrl"
            data-testId="tax-scenarios-download-large-csv-btn"
          >
            Download Large CSV
          </v-btn>
          <download-csv class="btn btn-default" name="gainLoss.csv" :data="gainLossData" :labels="labels" v-else>
            <v-btn data-testId="tax-scenarios-download-csv-btn">Download CSV</v-btn>
          </download-csv>
        </v-flex>
        <v-flex xs6 pa-1>
          <v-card :class="{ incompleteGL: !reportData.matchSuccessful }">
            <v-card-title class="title">Gain Loss</v-card-title>
            <v-layout column class="pa-3">
              <p v-if="!reportData.matchSuccessful" style="font-size: 18px">
                <b>ACTION REQUIRED: Gain loss data may be incomplete, Please confirm:</b>
              </p>
              <p v-if="!reportData.matchSuccessful" style="font-size: 18px">
                (A) All transactions for the period have been categorized (Transactions Menu)
              </p>
              <p v-if="!reportData.matchSuccessful" style="font-size: 18px">
                (B) Cost basis has been set for any account transfers in the (Accounting -> Ext cost basis Menu)
              </p>
              <p v-if="!reportData.matchSuccessful" style="font-size: 18px">
                (C) There are negative balances in the Asset Balance Column of actions report indicating withdrawal of
                more assets than acquired
              </p>
              <p v-if="!reportData.matchSuccessful" class="my-class"></p>
              <div data-testId="tax-scenarios-gain-loss-unrealized-div">
                Unrealized:
                <acct-num :amount="reportData.totalUnrealizedGainLoss" />
                <br />
              </div>
              <div
                v-if="reportData.totalRealizedShortTermGainLoss !== undefined"
                data-testId="tax-scenarios-gain-loss-realized-short-term-div"
              >
                Realized Short Term:
                <acct-num :amount="reportData.totalRealizedShortTermGainLoss" />
                <br />
              </div>
              <div
                v-if="reportData.totalRealizedLongTermGainLoss !== undefined"
                data-testId="tax-scenarios-gain-loss-realized-long-term-div"
              >
                Realized Long Term:
                <acct-num :amount="reportData.totalRealizedLongTermGainLoss" />
                <br />
              </div>
              <div
                v-if="reportData.totalRealizedUndatedGainLoss !== undefined"
                data-testId="tax-scenarios-gain-loss-realized-undated-div"
              >
                Realized Undated:
                <acct-num :amount="reportData.totalRealizedUndatedGainLoss" />
                <br />
              </div>
              <div
                v-if="reportData.totalExternalUnrealizedGainLoss !== undefined"
                data-testId="tax-scenarios-gain-loss-external-unrealized-div"
              >
                External Unrealized:
                <acct-num :amount="reportData.totalExternalUnrealizedGainLoss" />
                <br />
              </div>
              <div
                v-if="reportData.totalAdjustments !== undefined"
                data-testId="tax-scenarios-gain-loss-wrapping-related-div"
              >
                Wrapping Related Adjustments:
                <acct-num :amount="reportData.totalAdjustments" />
                <br />
              </div>
              <div
                v-if="reportData.totalAdjustments !== undefined"
                data-testId="tax-scenarios-gain-loss-impairment-expenses-div"
              >
                Impairment Expense:
                <acct-num :amount="reportData.totalImpairmentExpense * -1" />
                <br />
              </div>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex xs6 pa-1>
          <v-card height="100%">
            <v-card-title class="title">Exchange Rates</v-card-title>
            <v-layout row wrap class="pa-3">
              <v-flex xs6 v-for="(er, index) in exchangeRates" v-bind:key="`rate-${index}`">
                <v-layout row wrap>
                  <v-flex xs4 :data-testId="`tax-scenarios-gain-loss-exchange-rate-${er.displayName}-name`">
                    {{ er.displayName }}:
                  </v-flex>
                  <v-flex
                    xs8
                    v-if="!er.editing"
                    :data-testId="`tax-scenarios-gain-loss-exchange-rate-${er.displayName}-rate`"
                  >
                    {{ er.overrideRate }}
                    <v-icon
                      @click="er.editing = true"
                      size="small"
                      :data-testId="`tax-scenarios-gain-loss-exchange-rate-${er.displayName}-edit-btn`"
                      >edit</v-icon
                    >
                  </v-flex>
                  <v-flex xs8 v-else>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-text-field v-model="er.overrideRate" clear-icon="delete" solo></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-icon
                          @click="er.editing = false"
                          :data-testId="`tax-scenarios-gain-loss-exchange-rate-${er.displayName}-edit-enter-btn`"
                          >checkmark</v-icon
                        >
                        <v-icon
                          @click="cancelEdit(er)"
                          :data-testId="`tax-scenarios-gain-loss-exchange-rate-${er.displayName}-edit-cancel-btn`"
                          >delete</v-icon
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 pa-1>
          <v-card>
            <v-layout justify-space-between>
              <v-card-title class="title">Summary Lines</v-card-title>
              <download-csv name="summary.csv" :data="summaryLineData" :fields="summaryLineHeaders">
                <v-btn data-testId="tax-scenarios-gain-loss-download-summary-btn">Download Summary</v-btn>
              </download-csv>
            </v-layout>
            <v-layout column class="pa-3">
              <v-layout row wrap>
                <v-flex xs1></v-flex>
                <v-flex xs1>Ending Balance</v-flex>
                <v-flex xs2>Cumulative Cost</v-flex>
                <v-flex xs2 v-if="impair">Carrying Cost</v-flex>
                <v-flex xs2>Ending Gain / Loss</v-flex>
                <v-flex xs2>Ending Unrealized</v-flex>
                <v-flex xs2 v-if="impair">Impairement Expense</v-flex>
              </v-layout>
              <v-layout row wrap v-for="(sl, si) in reportData.summaryLines" v-bind:key="`sl-${si}`">
                <v-flex xs1 :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-coin-name`">{{
                  sl.value.ticker
                }}</v-flex>
                <v-flex xs1 :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-ending-balance`">{{
                  sl.runningBalance ? formatNumber(sl.runningBalance.amount, 2, 5) : ''
                }}</v-flex>
                <v-flex xs2 :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-cumulative-cost`">{{
                  sl.cumulativeCost ? sym() + formatNumber(sl.cumulativeCost) : ''
                }}</v-flex>
                <v-flex
                  xs2
                  v-if="impair"
                  :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-carrying-cost`"
                >
                  {{ sl.impairedCumulativeCost ? formatNumber(sl.impairedCumulativeCost, 2, 5) : '' }}
                </v-flex>
                <v-flex xs2>
                  <div v-if="sl.runningGainLoss">
                    <div
                      v-if="sl.runningGainLoss.shortTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-short-term-gl`"
                    >
                      ST:
                      <acct-num :amount="sl.runningGainLoss.shortTermGainLoss" />
                    </div>
                    <div
                      v-if="sl.runningGainLoss.longTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-long-term-gl`"
                    >
                      LT:
                      <acct-num :amount="sl.runningGainLoss.longTermGainLoss" />
                    </div>
                    <div
                      v-if="sl.runningGainLoss.undatedGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-undated-gl`"
                    >
                      UD:
                      <acct-num :amount="sl.runningGainLoss.undatedGainLoss" />
                    </div>
                  </div>
                </v-flex>
                <v-flex xs2 :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-unrealized-gl`">
                  UR <acct-num :amount="sl.runningUnrealizedGainLoss" />
                </v-flex>
                <v-flex
                  xs2
                  v-if="impair"
                  :data-testId="`tax-scenarios-gain-loss-summary-${sl.value.ticker}-impairment-expense`"
                >
                  <acct-num :amount="sl.impairmentExpense * -1" />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 pa-1>
          <v-data-table class="elevation-5" :hide-actions="true" :items="items" :headers="headers">
            <template slot="no-data">&nbsp;</template>
            <template slot="headers" slot-scope="props">
              <tr class="header-row">
                <th></th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="header.align === 'left' ? 'header-col-left' : ''"
                >
                  {{ header.text }}
                </th>
              </tr>
              <tr>
                <td class="title" colspan="3" v-bind:class="{ incomplete: !reportData.matchSuccessful }">
                  Scenario Results
                </td>
                <td
                  colspan="5"
                  v-if="!reportData.matchSuccessful"
                  class="incomplete"
                  data-testId="tax-scenarios-gain-loss-scenario-results-incomplete-div"
                >
                  Incomplete Report
                </td>
              </tr>
            </template>
            <template slot="items" slot-scope="txn">
              <tr>
                <td>
                  <v-icon v-if="txn.item.matchSuccessful" color="success">check</v-icon>
                  <v-tooltip top v-else>
                    <v-icon slot="activator" small class="incomplete">warning</v-icon>
                    <ul>
                      <li v-for="(err, index) in txn.item.matchErrors" v-bind:key="'er-' + txn.item.id + '-' + index">
                        {{ err }}
                      </li>
                    </ul>
                  </v-tooltip>
                </td>
                <td
                  style="white-space: nowrap"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-txn-id`"
                >
                  <blockchain-explorer-link :id="txn.item.taxEventId" />
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-datetime`">
                  {{ toOrgLocalTime(txn.item.timeSEC, true) }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-ticker`">
                  {{ txn.item.value.ticker }}
                </td>
                <td
                  v-bind:class="{
                    loss: txn.item.lineType === 'Disposed',
                    gain: txn.item.lineType === 'Acquired',
                  }"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-amount`"
                >
                  {{ formatNumber(txn.item.value.amount, 2, 4) }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-cost-basis`">
                  {{ txn.item.cost ? sym() + formatNumber(txn.item.cost) : '' }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-proceeds`">
                  {{ txn.item.proceeds ? sym() + formatNumber(txn.item.proceeds) : '' }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-cost-relieved`">
                  {{ txn.item.costRelieved ? sym() + formatNumber(txn.item.costRelieved) : '' }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-cumulative`">
                  {{ txn.item.cumulativeCost ? sym() + formatNumber(txn.item.cumulativeCost) : '' }}
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-exchange-rate`">
                  <acct-num :amount="txn.item.exchangeRate" :gain-loss-coloring="false" />
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-ur-gl`">
                  <acct-num :amount="txn.item.unrealizedGainLoss" />
                </td>
                <td
                  v-if="showExternal"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-ur-gl-external`"
                >
                  <acct-num :amount="txn.item.externalUnrealizedGainLoss" />
                </td>
                <td>
                  <div v-if="txn.item.realizedGainLoss">
                    <div
                      v-if="txn.item.realizedGainLoss.shortTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-st-gl`"
                    >
                      ST:
                      <acct-num :amount="txn.item.realizedGainLoss.shortTermGainLoss" />
                    </div>
                    <div
                      v-if="txn.item.realizedGainLoss.longTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-lt-gl`"
                    >
                      LT:
                      <acct-num :amount="txn.item.realizedGainLoss.longTermGainLoss" />
                    </div>
                    <div
                      v-if="txn.item.realizedGainLoss.undatedGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-ud-gl`"
                    >
                      UD:
                      <acct-num :amount="txn.item.realizedGainLoss.undatedGainLoss" />
                    </div>
                  </div>
                </td>
                <td v-if="impair" :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-impairment`">
                  <acct-num :amount="txn.item.matchSuccessful ? txn.item.impairmentExpense : 0" />
                </td>
                <td
                  v-if="impair"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-carry-exchange-rate`"
                >
                  <acct-num
                    :amount="txn.item.matchSuccessful ? txn.item.carryingExchangeRate : 0"
                    :gain-loss-coloring="false"
                  />
                </td>
                <td v-if="impair" :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-carrying-value`">
                  <acct-num
                    :amount="txn.item.matchSuccessful ? txn.item.carryingValue : 0"
                    :gain-loss-coloring="false"
                  />
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-balance`">
                  {{ txn.item.runningBalance ? formatNumber(txn.item.runningBalance.amount, 2, 5) : '' }}
                </td>
                <td>
                  <div v-if="txn.item.runningGainLoss">
                    <div
                      v-if="txn.item.runningGainLoss.shortTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-st-gl`"
                    >
                      ST:
                      <acct-num :amount="txn.item.runningGainLoss.shortTermGainLoss" />
                    </div>
                    <div
                      v-if="txn.item.runningGainLoss.longTermGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-lt-gl`"
                    >
                      LT:
                      <acct-num :amount="txn.item.runningGainLoss.longTermGainLoss" />
                    </div>
                    <div
                      v-if="txn.item.runningGainLoss.undatedGainLoss"
                      :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-ud-gl`"
                    >
                      UD:
                      <acct-num :amount="txn.item.runningGainLoss.undatedGainLoss" />
                    </div>
                  </div>
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-ur-gl`">
                  <acct-num :amount="txn.item.runningUnrealizedGainLoss" />
                </td>
                <td
                  v-if="showExternal"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-running-external-gl`"
                >
                  <acct-num :amount="txn.item.runningExternalUnrealizedGainLoss" />
                </td>
                <td
                  v-if="showAdjustments"
                  :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-adjustments`"
                >
                  <acct-num :amount="txn.item.adjustments" />
                </td>
                <td :data-testId="`tax-scenarios-gain-loss-report-${txn.item.taxEventId}-match-details`">
                  <a @click="showDetails = true" v-if="!showDetails">{{ $tc('_showDetails') }}</a>
                  <ul style="list-style: none; width: 450px; padding-left: 0" v-if="showDetails">
                    <li
                      v-for="match of txn.item.matches"
                      v-bind:key="`m-${txn.item.taxEventId}-${match.acquiredTaxEventId}-${match.disposedTaxEventId}`"
                    >
                      {{ formatMatch(txn.item, match) }}
                      <!--                      {{toOrgLocalTime(match.acquiredDateTimeSEC)}}:-->
                      <!--                      {{match.costRelieved ?  formatNumber(match.costRelieved, 2, 5) : ''}}-->
                    </li>
                  </ul>
                </td>
                <!--                <td>-->
                <!--                  {{txn}}-->
                <!--                </td>-->
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import * as math from 'mathjs';

import BlockchainExplorerLink from '../../components/transactions/BlockchainExplorerLink';
import AcctNum from '../accounting/AcctNum';

export default {
  props: [],
  apollo: {
    $client: 'rptApolloClient',
  },
  data: () => ({
    startDateShow: false,
    endDateShow: false,
    startDate: '',
    endDate: '',
    reportData: null,
    taxStrategy: null,
    capitalizeFees: true,
    specIdNetZero: false,
    isLoading: false,
    error: null,
    hasError: false,
    showDetails: false,
    impair: false,
    ignoreNFTs: false,
    taxMethods: [
      {
        id: 'FIFO',
        name: 'First In, First Out (FIFO)',
      },
      {
        id: 'LIFO',
        name: 'Last In, First Out (LIFO)',
      },
      {
        id: 'CostAverage',
        name: 'Cost Averaging',
      },
      {
        id: 'SpecificIdentification',
        name: 'Specific Identification (pre-configured rules)',
      },
    ],
    exchangeRates: [],
    summaryLineData: [],
    summaryLineHeaders: [
      'Token',
      'Ending Balance',
      'Cumulative Cost',
      'Carrying Cost',
      'Ending Gain / Loss : ST',
      'Ending Gain / Loss : LT',
      'Ending Gain / Loss : UD',
      'Ending Unrealized',
      'Impairment Expense',
    ],
  }),
  computed: {
    allowImpairment() {
      return this.checkFeatureFlag('impairment', this.$store.getters.features);
    },
    allowSpecIdNetZero() {
      return (
        this.checkFeatureFlag('spec-id-net-zero', this.$store.getters.features) &&
        this.taxStrategy === 'SpecificIdentification'
      );
    },
    items() {
      return this.reportData.lines;
    },
    showExternal() {
      const f = this.reportData.lines.find((m) => m.externalUnrealizedGainLoss);
      return !!f;
    },
    showAdjustments() {
      const f = this.reportData.lines.find((m) => m.adjustments);
      return !!f;
    },
    headers() {
      const headers = [
        { text: this.$tc('_txnId') },
        { text: this.$tc('_txnTime') },
        { text: this.$tc('_coin') },
        { text: this.$tc('_amount') },
        { text: this.$tc('_costBasis') },
        { text: this.$tc('_proceeds') },
        { text: this.$tc('_costBasisRelieved') },
        { text: this.$tc('_cumulativeCost') },
        { text: this.$tc('_exchangeRate') },
        { text: this.$tc('_unrealizedGains') },
        { text: this.$tc('_gainLoss') },
        { text: 'Impaired Expense', show: this.impair },
        { text: 'Impaired To Rate', show: this.impair },
        { text: 'Impaired To Cost', show: this.impair },
        { text: this.$tc('_runningBalance') },
        { text: this.$tc('_runningGainLoss') },
        { text: this.$tc('_runningUnrealized') },
        { text: this.$tc('_matchDetails'), align: 'left' },
      ];

      if (this.showExternal) {
        headers.splice(10, 0, {
          text: this.$tc('_externalUnrealizedGainLoss'),
        });

        headers.splice(15, 0, {
          text: this.$tc('_runningExternalUnrealizedGainLoss'),
        });
      }

      return headers.filter((h) => h.show !== false);
    },
    labels() {
      const labels = {
        txnId: this.$tc('_txnId'),
        txnTime: this.$tc('_txnTime'),
        coin: this.$tc('_coin'),
        amount: this.$tc('_amount'),
        costBasis: this.$tc('_costBasis'),
        costBasisRelieved: this.$tc('_costBasisRelieved'),
        cumulativeCost: this.$tc('_cumulativeCost'),
        proceeds: this.$tc('_proceeds'),
        exchangeRate: this.$tc('_exchangeRate'),
        unrealizedGains: this.$tc('_unrealizedGains'),
        udGainLoss: this.$tc('_udGainLoss'),
        stGainLoss: this.$tc('_stGainLoss'),
        ltGainLoss: this.$tc('_ltGainLoss'),
        runningBalance: this.$tc('_runningBalance'),
        udRunningGainLoss: this.$tc('_udRunningGainLoss'),
        stRunningGainLoss: this.$tc('_stRunningGainLoss'),
        ltRunningGainLoss: this.$tc('_ltRunningGainLoss'),
        runningUnrealized: this.$tc('_runningUnrealized'),
      };
      return labels;
    },
    gainLossData() {
      const sym = this.sym();
      const gainLossData = this.reportData.lines.map((txn) => {
        return this.mapGainLoss(txn, sym);
      });
      return gainLossData;
    },
    currentTimezone() {
      return this.$store.state.currentOrg.timezone ? this.$store.state.currentOrg.timezone : 'N/A';
    },
    overrideRates() {
      const m = this.exchangeRates.filter((m) => m.rate !== m.overrideRate);
      return m;
    },
  },
  methods: {
    formatSummaryLineData() {
      const sym = this.sym();
      const gainLossCol = (x) => {
        const ret = [];
        ret.push(x.runningGainLoss?.shortTermGainLoss ?? '');
        ret.push(x.runningGainLoss?.longTermGainLoss ?? '');
        ret.push(x.runningGainLoss?.undatedGainLoss ?? '');

        return ret;
      };

      const data = this.reportData.summaryLines.map((x) => {
        const gainLossCols = gainLossCol(x);
        return {
          Token: x.value.ticker,
          'Ending Balance': x.runningBalance ? x.runningBalance.amount : '',
          'Cumulative Cost': x.cumulativeCost ? sym + x.cumulativeCost : '',
          'Carrying Cost': x.impairedCumulativeCost ? x.impairedCumulativeCost : '',
          'Ending Gain / Loss : ST': gainLossCols[0],
          'Ending Gain / Loss : LT': gainLossCols[1],
          'Ending Gain / Loss : UD': gainLossCols[2],
          'Ending Unrealized': x.runningUnrealizedGainLoss ? `UR ${x.runningUnrealizedGainLoss}` : '',
          'Impairment Expense': x.impairmentExpense * -1 ?? '',
        };
      });
      const fields = [
        'Token',
        'Ending Balance',
        'Cumulative Cost',
        'Carrying Cost',
        'Ending Gain / Loss : ST',
        'Ending Gain / Loss : LT',
        'Ending Gain / Loss : UD',
        'Ending Unrealized',
        'Impairment Expense',
      ];

      if (!this.impair) {
        fields.splice(8, 1);
        fields.splice(3, 1);
      }
      this.summaryLineHeaders = fields;
      this.summaryLineData = data;
    },
    mapGainLoss(txn, sym) {
      const matchDetails = this.formatMatches(txn).join('\r\n');

      const gainLoss = {
        txnId: txn.taxEventId,
        txnTime: this.toOrgLocalTime(txn.timeSEC, true),
        coin: txn.value.ticker,
        amount: this.formatNumber(txn.value.amount, 2, 4),
        costBasis: txn.cost ? sym + this.formatNumber(txn.cost) : '',
        costBasisRelieved: txn.costRelieved ? sym + this.formatNumber(txn.costRelieved) : '',
        cumulativeCost: txn.cumulativeCost ? sym + this.formatNumber(txn.cumulativeCost) : '',
        proceeds: txn.proceeds ? sym + this.formatNumber(txn.proceeds) : '',
        exchangeRate: txn.exchangeRate ? this.accountingFormat(txn.exchangeRate) : '',
        unrealizedGains: txn.unrealizedGainLoss ? this.accountingFormat(txn.unrealizedGainLoss) : '',
        udGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.undatedGainLoss) : '',
        stGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.shortTermGainLoss) : '',
        ltGainLoss: txn.realizedGainLoss ? this.accountingFormat(txn.realizedGainLoss.longTermGainLoss) : '',
        runningBalance: txn.runningBalance ? this.formatNumber(txn.runningBalance.amount, 2, 5) : '',
        udRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.undatedGainLoss) : '',
        stRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.shortTermGainLoss) : '',
        ltRunningGainLoss: txn.runningGainLoss ? this.accountingFormat(txn.runningGainLoss.longTermGainLoss) : '',
        runningUnrealized: txn.runningUnrealizedGainLoss ? this.accountingFormat(txn.runningUnrealizedGainLoss) : '',
        matchDetails,
      };
      return gainLoss;
    },
    cancelEdit(er) {
      er.overrideRatte = null;
      er.editing = false;
    },
    formatMatch(txn, m) {
      let dateTimeSEC;
      let prefix;
      if (txn.taxEventId === m.acquiredTaxEventId) {
        dateTimeSEC = m.disposedDateTimeSEC;
        prefix = 'Disposed on';
      } else if (txn.taxEventId === m.disposedTaxEventId) {
        dateTimeSEC = m.acquiredDateTimeSEC;
        prefix = 'Acquired on';
      } else {
        return 'Problem detecting match';
      }

      return `${prefix} ${this.toOrgLocalTime(dateTimeSEC, true)} : ${m.matchedAmount.ticker} ${this.formatNumber(
        m.matchedAmount.amount,
        2,
        5
      )} : ${this.accountingFormat(m.costRelieved)}`;
    },
    formatMatches(txn) {
      const matchDetails = txn.matches ? txn.matches.map((m) => this.formatMatch(txn, m)) : [];
      return matchDetails;
    },
    accountingFormat(value) {
      if (value === undefined || value === '') {
        return '';
      }

      const bn = math.bignumber(value);

      if (value < 0) {
        return this.sym() + '(' + this.formatNumber(bn.abs()) + ')';
      } else {
        return this.sym() + this.formatNumber(bn);
      }
    },
    formatNumber(value, minDecimalPlaces, maxDecimalPlaces) {
      const minDec = minDecimalPlaces || 2;
      const maxDec = maxDecimalPlaces || 2;
      if (value !== undefined && value !== '') {
        const vn = math.bignumber(value);
        const n = vn.toDecimalPlaces(maxDecimalPlaces);
        const ret = n.toNumber().toLocaleString(undefined, {
          minimumFractionDigits: minDec,
          maximumFractionDigits: maxDec,
        });
        return ret;
      } else {
        return '';
      }
    },
    runReport() {
      this.error = null;
      this.hasError = false;

      let overrideExchangeRates;
      if (this.overrideRates.length > 0) {
        overrideExchangeRates = this.overrideRates.map((m) => {
          return {
            currencyId: m.currencyId,
            rate: m.overrideRate,
          };
        });
      }

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        startDate: this.startDate,
        endDate: this.endDate,
        strategy: this.taxStrategy,
        config: {
          capitalizeFees: this.capitalizeFees,
          specIdNetZero: this.specIdNetZero,
        },
        overrideExchangeRates,
        impair: this.impair,
        ignoreNFTs: this.ignoreNFTs,
      };

      this.isLoading = true;
      this.hasError = false;

      this.$apollo
        .query({
          // Query
          query: gql`
            query (
              $orgId: String!
              $startDate: String
              $endDate: String
              $strategy: TaxStrategyType
              $config: TaxConfigInput
              $overrideExchangeRates: [ExchangeRateOverride]
              $impair: Boolean
              $ignoreNFTs: Boolean
            ) {
              taxes {
                runScenario(
                  orgId: $orgId
                  startDate: $startDate
                  endDate: $endDate
                  strategy: $strategy
                  config: $config
                  overrideExchangeRates: $overrideExchangeRates
                  impair: $impair
                  ignoreNFTs: $ignoreNFTs
                ) {
                  exchangeRatesToBaseCurrency {
                    ticker
                    rate
                  }
                  exchangeRatePointers {
                    displayName
                    currencyId
                    rate
                  }
                  bucketResults {
                    ticker
                    realizedShortTermGainLoss
                    realizedLongTermGainLoss
                    realizedUndatedGainLoss
                    unrealizedGainLoss
                    unspentAmount {
                      type
                      amount
                      ticker
                      fiat
                      coin
                      coinUnit
                    }
                    unspentCostBasis
                    matchedSuccessfully
                    adjustments
                  }
                  lines {
                    taxEventId
                    timeSEC
                    lineType
                    value {
                      type
                      amount
                      ticker
                      fiat
                      coin
                      coinUnit
                    }
                    cost
                    matchSuccessful
                    cumulativeCost
                    impairedCumulativeCost
                    costRelieved
                    proceeds
                    exchangeRate
                    realizedGainLoss {
                      shortTermGainLoss
                      longTermGainLoss
                      undatedGainLoss
                    }
                    unrealizedGainLoss
                    externalUnrealizedGainLoss
                    runningBalance {
                      type
                      amount
                      ticker
                      fiat
                      coin
                      coinUnit
                    }
                    runningGainLoss {
                      shortTermGainLoss
                      longTermGainLoss
                      undatedGainLoss
                    }
                    runningUnrealizedGainLoss
                    runningExternalUnrealizedGainLoss
                    matchErrors
                    matches {
                      acquiredTaxEventId
                      disposedTaxEventId
                      matchedAmount {
                        type
                        amount
                        ticker
                        fiat
                        coin
                        coinUnit
                      }
                      shortTermGainLossAmount
                      longTermGainLossAmount
                      costRelieved
                      acquiredDateTimeSEC
                      disposedDateTimeSEC
                    }
                    adjustments
                    impairmentExpense
                    carryingValue
                    carryingExchangeRate
                  }
                  summaryLines {
                    taxEventId
                    timeSEC
                    lineType
                    value {
                      type
                      amount
                      ticker
                      fiat
                      coin
                      coinUnit
                    }
                    cost
                    matchSuccessful
                    cumulativeCost
                    impairedCumulativeCost
                    costRelieved
                    proceeds
                    exchangeRate
                    realizedGainLoss {
                      shortTermGainLoss
                      longTermGainLoss
                      undatedGainLoss
                    }
                    unrealizedGainLoss
                    externalUnrealizedGainLoss
                    runningBalance {
                      type
                      amount
                      ticker
                      fiat
                      coin
                      coinUnit
                    }
                    runningGainLoss {
                      shortTermGainLoss
                      longTermGainLoss
                      undatedGainLoss
                    }
                    runningUnrealizedGainLoss
                    runningExternalUnrealizedGainLoss
                    matchErrors
                    matches {
                      acquiredTaxEventId
                      disposedTaxEventId
                      matchedAmount {
                        type
                        amount
                        ticker
                        fiat
                        coin
                        coinUnit
                      }
                      shortTermGainLossAmount
                      longTermGainLossAmount
                      costRelieved
                      acquiredDateTimeSEC
                      disposedDateTimeSEC
                    }
                    impairmentExpense
                    carryingValue
                    carryingExchangeRate
                  }
                  matchSuccessful
                  totalRealizedShortTermGainLoss
                  totalRealizedLongTermGainLoss
                  totalRealizedUndatedGainLoss
                  totalAdjustments
                  totalUnrealizedGainLoss
                  totalExternalUnrealizedGainLoss
                  totalImpairmentExpense
                  periodStartSEC
                  periodEndSEC
                  largeFileDownloadUrl
                  actionsDownloadUrl
                }
              }
            }
          `,
          variables: vars,
          fetchPolicy: 'network-only',
        })
        .then((m) => {
          this.reportData = m.data.taxes.runScenario;
          if (this.reportData.exchangeRatePointers) {
            this.exchangeRates = this.reportData.exchangeRatePointers.map((m) => {
              // <span>{{er.ticker}}: {{er.rate}}</span>

              this.$set(m, 'overrideRate', m.rate);
              this.$set(m, 'editing', false);
              return m;
            });
          }

          this.formatSummaryLineData();

          this.isLoading = false;
        })
        .catch((err) => {
          this.error =
            err?.graphQLErrors.map((e) => `${e.message} ${e.traceId ? ` - Error ID: ${e.traceId}` : ''}`) ?? [];
          this.isLoading = false;
          this.hasError = true;
        });
    },
    toggleDetails(txn) {
      this.$set(txn.item, 'showDetails', !txn.item.showDetails);
      // txn.item.showDetails = !txn.item.showDetails;
    },
  },
  components: {
    AcctNum,
    BlockchainExplorerLink,
  },
};
</script>

<style scoped>
.header-row {
  text-align: right;
}

.header-col-left {
  text-align: left;
}

.input-row {
  text-align: right;
}

.incomplete {
  color: #ff6b00 !important;
}

.incomplete-row {
  color: #ff6b00 !important;
  text-align: right;
}

.total-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: right;
  height: 25px;
}

.loss {
  color: #d00600;
}

.gain {
  color: #00a500;
}

.exchange-rate {
  vertical-align: top;
}

div.div-grid {
  display: grid;
}

.incompleteGL {
  color: #d00600 !important;
}

.my-class {
  margin-bottom: 20px;
}
</style>
