<template>
  <div>
    <div class="headline">Tax starting data?</div>
    <p>
      Do you want to set a starting date for your taxes and setup overriding cost average data? All transactions before
      this data will be marked as ignored (which will ignore them from tax matching, balance reports, and more), and
      we'll create starting transactions for any starting balances.
    </p>

    <v-form ref="costAverageStep" v-model="valid">
      <v-alert :value="error">{{ error }}</v-alert>
      <v-layout row wrap>
        <v-flex xs6>
          <v-checkbox label="Set tax starting date info" v-model="setStartingDate" />
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="setStartingDate">
        <v-flex xs4>
          <v-menu
            lazy
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              label="Tax Data Starting Date"
              v-model="context.startDate"
              :rules="[rules.reqIfStarting]"
              readonly
            ></v-text-field>
            <v-date-picker v-model="context.startDate" no-title scrollable actions> </v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <template v-if="setStartingDate">
        <v-layout row wrap v-for="(line, line_index) in lines" :key="line_index">
          <v-flex xs4>
            <v-text-field v-model="line.ticker" autocapitalize="characters" label="Ticker"></v-text-field>
          </v-flex>
          <v-flex xs4 offset-xs1>
            <v-text-field v-model="line.value" label="Cost Average" :rules="[rules.number]"></v-text-field>
          </v-flex>
          <v-flex xs1 class="pa-4">
            <v-icon @click="deleteLine(line, line_index)">delete</v-icon>
          </v-flex>
        </v-layout>
      </template>
      <v-layout row wrap v-if="setStartingDate">
        <v-flex xs2><a @click="addLine()">Additional Override +</a></v-flex>
      </v-layout>
      <v-layout row wrap class="my-3">
        <v-flex xs12>
          <v-btn color="primary" @click="goNext">Next</v-btn>
          <v-btn flat @click="back">Back</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
// import moment from "moment";
// import gql from "graphql-tag";

import moment from 'moment-timezone';

export default {
  props: ['next', 'back', 'context', 'save'],
  data() {
    return {
      isLoading: 0,
      valid: true,
      setStartingDate: false,
      lines: [
        {
          ticker: '',
          value: '',
        },
      ],
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
        reqIfStarting: (value) => (this.setStartingDate ? !!value || 'Required.' : true),
      },
      error: null,
    };
  },
  methods: {
    addLine() {
      this.lines.push({
        ticker: '',
        value: '',
      });
    },
    deleteLine(line, index) {
      this.lines.splice(index, 1);
    },
    goNext() {
      if (this.setStartingDate) {
        if (!this.context.startDate) {
          this.error = 'Must select a starting date';
          return;
        }

        const list = [];
        for (const l of this.lines) {
          // validate tickers
          if (l.value !== '' && l.ticker !== '' && !isNaN(Number(l.value))) {
            list.push({
              ticker: l.ticker,
              averageCost: l.value.replace(',', ''), // Number(l.value)
            });
          }
        }
        this.context.startingBalanceDetails = list;
        this.next();
      } else {
        this.next();
      }
    },
  },
  computed: {
    dateMax() {
      const date = moment().format('L');
      const ret = moment.utc(date).hours(23).minutes(59).second(59);
      return ret.toISOString();
    },
  },
};
</script>
