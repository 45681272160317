<template>
  <div>
    <div class="headline">Tax starting date?</div>
    <p>
      All transactions before this data will be marked as ignored (which will ignore them from tax matching, balance
      reports, and more), and we'll create starting transactions for any starting balances.
    </p>

    <v-form ref="dateForm" v-model="valid">
      <v-date-picker v-model="context.startDate" :landscape="true" :max="dateMax" />
    </v-form>

    <v-btn color="primary" :disabled="!nextEnabled" @click="tryNext">Next</v-btn>
    <v-btn flat @click="back">Back</v-btn>
  </div>
</template>
<script>
import moment from 'moment-timezone';
export default {
  props: ['next', 'back', 'context', 'save'],
  data() {
    return {
      categorized: 5,
      total: 100,
      valid: true,
      // startDate: ""
    };
  },
  methods: {
    tryNext() {
      // this.context.startDate = this.startDate;
      this.next();
    },
  },
  computed: {
    nextEnabled() {
      if (this.context.startDate && this.context.startDate !== '') {
        return true;
      } else {
        return false;
      }
    },
    dateMax() {
      const date = moment().format('L');
      const ret = moment.utc(date).hours(23).minutes(59).second(59);
      return ret.toISOString();
    },
  },
};
</script>
