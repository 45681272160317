<template>
  <v-layout row wrap>
    <v-flex xs12 class="text-xs-center">
      <h1 class="display-1">Setup Your Taxes</h1>
    </v-flex>
    <v-flex offset-xs3 xs6 class="text-xs-center pa-3">
      <p class="body-2">To get started, we need you to answer a few questions.</p>
    </v-flex>
    <v-flex offset-xs2 xs8>
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1"> Catchup </v-stepper-step>

        <v-stepper-content step="1">
          <v-form ref="step1" v-model="step1Valid">
            <div class="headline">
              Please select a tax strategy to use. It is possible to change this, but can be difficult.
            </div>
            <v-radio-group v-model="context.taxStrategy" class="pa-4" :rules="step1Rules">
              <v-radio value="FIFO">
                <div slot="label"><b>FIFO</b> &nbsp; Sells and Spends will use the earliest coins they can.</div>
              </v-radio>
              <v-radio value="LIFO">
                <div slot="label">
                  <b>LIFO</b> &nbsp; Sells and Spends will select the most recently received coins first.
                </div>
              </v-radio>
              <v-radio value="CostAverage">
                <div slot="label"><b>Cost Average</b> &nbsp; The average of the cost will be used as a cost basis.</div>
              </v-radio>
              <v-radio value="SpecificIdentification">
                <div slot="label">
                  <b>Specific ID</b> &nbsp; Tax lots will be selected based on set coin selection strategies.
                </div>
              </v-radio>
            </v-radio-group>
            <v-btn v-if="steps.length > 1" color="primary" @click="next1">Next</v-btn>
            <v-btn v-else color="primary" @click="saveState" :disabled="!canSave">Save</v-btn>
          </v-form>
        </v-stepper-content>

        <template v-for="n in steps">
          <v-stepper-step :complete="currentStep > n.id" :key="`${n.id}-step`" :step="n.id">
            {{ n.name }}
          </v-stepper-step>

          <v-stepper-content :step="n.id" v-bind:key="n.id">
            <cost-average-override-step
              v-if="n.component === 'costAverageOverride' && currentStep === n.id"
              :back="() => back(n)"
              :next="() => next(n)"
              :context="context"
              :save="saveState"
            />
            <cost-average-finish-step
              v-if="n.component === 'costAverageFinish' && currentStep === n.id"
              :back="() => back(n)"
              :context="context"
              :save="saveState"
              :save-errors="saveErrors"
              :saving="saving"
            />
            <tax-starting-date-step
              v-if="n.component === 'taxStartingDate' && currentStep === n.id"
              :back="() => back(n)"
              :next="() => next(n)"
              :context="context"
              :save="saveState"
            />
            <tax-starting-details-step
              v-if="n.component === 'taxStartingDetails' && currentStep === n.id"
              :back="() => back(n)"
              :next="() => next(n)"
              :context="context"
              :save="saveState"
            />
            <tax-excluded-coins-step
              v-if="n.component === 'taxExcludedCoins' && currentStep === n.id"
              :back="() => back(n)"
              :next="() => next(n)"
              :context="context"
              :save="saveState"
            />
            <tax-finish-step
              v-if="n.component === 'taxFinish' && currentStep === n.id"
              :back="() => back(n)"
              :context="context"
              :save="saveState"
              :save-errors="saveErrors"
              :saving="saving"
            />
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import costAverageFinishStep from './CostAverageFinishStep';
import costAverageOverrideStep from './CostAverageOverrideStep';
import TaxExcludedCoinsStep from './taxExcludedCoinsStep';
import TaxFinishStep from './TaxFinishStep';
import TaxStartingDateStep from './taxStartingDateStep';
import TaxStartingDetailsStep from './taxStartingDetailsStep';

export default {
  params: [],
  props: ['taxStrategy'],
  data() {
    return {
      step1Valid: false,
      context: {
        taxStrategy: this.taxStrategy,
      },
      currentStep: '1',
      costAverageDate: '',
      step1Rules: [(v) => !!v || 'Please select a Tax Strategy'],
      saveErrors: [],
      saving: false,
    };
  },
  methods: {
    back(n) {
      this.currentStep = n.id - 1;
    },
    next1() {
      if (this.$refs.step1.validate()) {
        this.currentStep = 2;
      }
    },
    next(n) {
      this.currentStep = n.id + 1;
    },
    async saveState() {
      this.saveErrors = [];

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        taxSetup: {
          taxStrategy: this.context.taxStrategy,
        },
      };

      if (this.context.costAverageDate) {
        vars.taxSetup.costAverageOverrideEndDate = this.context.costAverageDate;
      }

      if (this.context.costAverageOverrides) {
        vars.taxSetup.costAverageOverrides = this.context.costAverageOverrides;
      }

      if (this.context.excludedCoins) {
        vars.taxSetup.excludedCoins = this.context.excludedCoins;
      }

      if (this.context.startingBalanceDetails) {
        vars.taxSetup.startingBalanceDetails = this.context.startingBalanceDetails;
        vars.taxSetup.defaultToZeroCostBasis = this.context.defaultToZeroCostBasis;
      }

      if (this.context.startDate) {
        vars.taxSetup.taxStartDate = this.context.startDate;
      }

      try {
        this.saving = true;
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $taxSetup: TaxSetupInput) {
              updateTaxSetup(orgId: $orgId, taxSetup: $taxSetup) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        });
        this.$emit('saved');
      } catch (e) {
        console.log(typeof e);
        console.dir(e);
        if (e.graphQLErrors && e.graphQLErrors.length > 0) {
          for (const err of e.graphQLErrors) {
            this.saveErrors.push(err.message);
          }
        }
      } finally {
        this.saving = false;
        this.context.taxStrategy = this.$store.state.currentOrg.taxStrategyType;
      }
    },
  },
  computed: {
    canSave() {
      if (this.context) {
        if (this.context.taxStrategy === 'FIFO' || this.context.taxStrategy === 'LIFO') {
          return true;
        }
      }
      return false;
    },
    steps() {
      if (this.context.taxStrategy === 'CostAverage') {
        return [
          {
            id: 2,
            name: 'Starting Details',
            component: 'costAverageOverride',
            last: false,
          },
          {
            id: 3,
            name: 'Finish',
            component: 'costAverageFinish',
            last: true,
          },
        ];
      } else if (this.context.initialSync === 'FullReconcile') {
        return [
          {
            id: 2,
            name: 'Finish',
            component: 'fullReconciliation',
            last: true,
          },
        ];
      } else if (this.context.taxStrategy === 'SpecificIdentification') {
        return [
          {
            id: 2,
            name: 'Starting Details',
            component: 'taxStartingDetails',
            last: false,
          },
          {
            id: 3,
            name: 'Excluded Coins',
            component: 'taxExcludedCoins',
            last: false,
          },
          {
            id: 4,
            name: 'Finish',
            component: 'taxFinish',
            last: true,
          },
        ];
      } else {
        return [];
      }
    },
  },
  components: {
    TaxExcludedCoinsStep,
    TaxStartingDateStep,
    TaxFinishStep,
    TaxStartingDetailsStep,
    costAverageOverrideStep,
    costAverageFinishStep,
  },
};
</script>
