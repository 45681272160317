<!-- https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component -->
<template>
  <div>
    <tax-scenarios />
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="10000" :top="true" multi-line vertical>
      {{ snackbarText }}
      <ul v-if="snackbarErrors.length">
        <li v-for="item in snackbarErrors" v-bind:key="item">{{ item }}</li>
      </ul>
      <v-btn flat @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import * as math from 'mathjs';
import moment from 'moment';

import { TransactionsTaxQuery } from '../../queries/transactionsPageQuery';
import TaxScenarios from './TaxScenarios';

export default {
  props: [],
  components: {
    TaxScenarios,
  },
  computed: {
    isLoadingAll: function () {
      return this.isLoading + this.isPaginationLoading > 0;
    },
    vars: function () {
      return {
        transactionFilter: this.filters,
        orgId: this.$store.state.currentOrg.id,
      };
    },
    selectedCount() {
      if (this.selected && this.selected.length) {
        return this.selected.length;
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      q: TransactionsTaxQuery,
      filters: {
        taxReconciliationFilter: 'Unreconciled',
        ignoreFilter: 'Unignored',
      },
      authors: ['Admin', 'Editor'],
      reconciliationStatuses: ['All', 'Reconciled', 'Unreconciled'],
      headers: [
        {},
        {
          text: this.$tc('_id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$tc('_date'),
          align: 'center',
          value: 'created',
        },
        {
          text: this.$tc('_taxReconciled'),
          align: 'center',
          value: 'taxReconciled',
        },
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'wallet',
        },
        {
          text: this.$tc('_ticker', 1),
          align: 'left',
          value: 'ticker',
        },
        {
          text: this.$t('_amount'),
          align: 'left',
          value: 'amount',
        },
        {
          text: this.$t('_cost'),
          align: 'left',
          value: 'cost',
        },
        {
          text: this.$t('_saleValue'),
          align: 'left',
          value: 'saleValue',
        },
        {
          text: this.$t('_exchangeRate'),
          align: 'left',
          value: 'cost',
        },
        {
          text: this.$t('_gainLoss'),
          align: 'left',
          value: 'cost',
        },
      ],
      transactions: { txns: [], count: 0 },
      categories: [],
      isLoading: 0,
      isPaginationLoading: 0,
      contacts: [],
      selected: [],
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      snackbarErrors: [],
    };
  },
  apollo: {},
  methods: {
    runMatch() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!) {
              runTaxMatch(orgId: $orgId) {
                success
                errors
              }
            }
          `,
          variables: vars,
        })
        .then((m) => {
          const data = m.data.runTaxMatch;
          if (data.errors && data.errors.length > 0) {
            this.snackbarText = this.$tc('_taxMatchFails');
            this.snackbarErrors = JSON.stringify(m.errors.join('<br />'));
            this.snackbarColor = 'error';
            this.snackbar = true;
          } else {
            this.snackbarText = this.$tc('_taxMatchSuccess');
            this.snackbarErrors = [];
            this.snackbarColor = 'success';
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.snackbarText = this.$tc('_taxMatchFails');
          this.snackbarErrors = err.message;
          this.snackbarColor = 'error';
          this.snackbar = true;
        });
    },
    combineSelectedTransactions() {
      if (this.selectedCount < 2) {
        throw new Error("Can't combine less than 2 transactions");
      }

      const txnIds = this.selected.map((m) => m.id);
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        txnIds,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $txnIds: [ID]!) {
              combineTransactions(orgId: $orgId, txnIds: $txnIds) {
                id
              }
            }
          `,
          variables: vars,
        })
        .then((res) => {
          if (res.errors && res.errors.length > 0) {
            const errors = [];
            if (res.errors && res.errors.length > 0) {
              errors.push(...res.errors);
            }

            this.snackbarText =
              this.$tc('_combinedTransactionsFailure', this.selectedCount) +
              ': ' +
              JSON.stringify(errors.join('<br />'));
            this.snackbarErrors = errors;
            this.snackbarColor = 'error';
            this.snackbar = true;
          } else {
            this.snackbarText = this.$tc('_combinedTransactionsSuccess', this.selectedCount);
            this.snackbarErrors = [];
            this.snackbarColor = 'success';
            this.snackbar = true;
          }

          this.selected = [];
          this.refresh();
        })
        .catch((err) => {
          this.snackbarText =
            this.$tc('_combinedTransactionsFailure', this.selectedCount) + ': ' + JSON.stringify(err.message);
          // this.snackbarErrors = errors;
          this.snackbarColor = 'error';
          this.snackbar = true;
        });
    },
    isPaginationLoadingHandler: function (isLoading) {
      this.isPaginationLoading = isLoading;
    },
    async refresh() {
      this.$refs.pagination.refetch();
    },
    renderDataTable: function (data) {
      this.transactions = data.transactions;
    },
    accountingFormat(value) {
      if (value === undefined) {
        return '';
      }

      const bn = math.bignumber(value);

      if (value < 0) {
        return '$' + '(' + this.formatNumber(bn.abs()) + ')';
      } else {
        return '$' + value;
      }
    },
    formatNumber(value) {
      if (value !== undefined) {
        const n = math.bignumber(value);
        return n.toNumber().toLocaleString();
      } else {
        return '';
      }
    },
    getCurrencySymbol(coin) {
      if (coin === 'BTC') {
        return '฿';
      } else if (coin === 'ETH') {
        return 'Ξ';
      } else if (coin === 'EOS') {
        return 'EOS';
      } else {
        return coin;
      }
    },
    toUtcDate(timestamp) {
      return moment(timestamp, 'X').utc().format('lll');
    },
    ignoreTxn(txn, ignore) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: txn.id,
        ignore,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!, $ignore: Boolean) {
              updateTransaction(orgId: $orgId, id: $id, ignore: $ignore) {
                id
              }
            }
          `,
          variables: vars,
        })
        .then(() => {
          this.refresh();
        });
    },
    unreconcileTxn(txn) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: txn.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              unReconcileTransaction(orgId: $orgId, id: $id)
            }
          `,
          variables: vars,
        })
        .then((d) => {
          if (d.data.unReconcileTransaction) {
            this.snackbar = true;
            this.snackbarText = 'Successfully Unreconciled Transaction';
            this.snackbarColor = 'success';
            this.refresh();
          } else {
            this.snackbar = true;
            this.snackbarText = 'Problem Unreconciling Transaction';
            this.snackbarColor = 'error';
            this.refresh();
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = 'Error Unreconciling ' + err;
          this.snackbarColor = 'error';
        });
    },
  },
};
</script>
<style>
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.value {
  padding: 0 !important;
  margin: 0 !important;
}

.rotate-90 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
</style>
